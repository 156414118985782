import React, { useState, useEffect } from "react"
import { Box,
   Link, 
   Text, 
   Image,
   Button,
   Center,
   Icon,
   Spinner,
   Skeleton,
   WrapItem,
   Wrap,
   Spacer,
   Select,
   Tooltip
} from "@chakra-ui/react"
import { Link as GatsbyLink } from 'gatsby'
import { 
   BiSort
} from "react-icons/bi"
import axios  from 'axios'
import Layout from '../components/layout.js';
import {
   getWindowParam,
   num2ja,
   AutoBrLinkMoreParser,
   Midasi4,
   Midasi5,
   RadioButtonsC5v1,
   Pagenavi1,
   Chart1
} from '../util/functions.js';

const ChannelDetail = (pageprops) => {
   const [params,setParams] = useState({
      ch:"",
      duration:"全期間",
      dateOrViewOrRegist:"日付",
      page:1,
   });
   const [pager,setPager] = useState({
      page:1,
      isFirstpage:true,
      isEndpage:false
   })
   const [videosCnt,setvideosCnt] = useState({
      videosCnt:0,
      pagecounts:0,
      finished:false,
   });
   const [channeldetail,setchanneldetail] = useState({
      channeldetail:[],
      thumbnailUrl:"",
      finished:false,
   });
   const [selectedVideoLists,setselectedVideoLists] = useState({
      selectedVideoLists:[],
      finished:false,
   });

   const [isDisabled,setisDisabled] = useState(true);
   const [show, setShow] = useState(false);
   const [kanrenWords,setkanrenWords] = useState({
      kanrenWords:[],
      finished:false
   });
   const [kanrenPosts,setkanrenPosts] = useState({
      kanrenPosts:[],
      finished:false
   });


   const getVideoCountfromCh = (param) => {
      setvideosCnt({
         videosCnt:0,
         pagecounts:0,
         finished:false,
      });
      var type="getVideoCountfromCh";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            // console.log(res.data);
            setvideosCnt({
               videosCnt:res.data.videoCount,
               pagecounts:Math.ceil(res.data.videoCount / 10),
               finished:true,
            });
         })
         .catch( (error) => {
            console.log(error);
            setvideosCnt({
               videosCnt:0,
               pagecounts:0,
               finished:true,
            });
      });
   }

   const getChannelDetails = (param) => {
      setchanneldetail({
         channeldetail:[],
         thumbnailUrl:"",
         finished:false,
      });
      var type="getChannelDetails";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            // console.log(res.data);
            setchanneldetail({
               channeldetail:res.data.channel,
               thumbnailUrl:res.data.trimed,
               finished:true,
            });
         })
         .catch( (error) => {
            console.log(error);
            setchanneldetail({
               channeldetail:[],
               thumbnailUrl:"",
               finished:true,
            });
      });
   }

   const getVideoListsOnePagefromCh = (param) => {
      setselectedVideoLists({
         selectedVideoLists:[],
         finished:false,
      });
      var type="getVideoListsOnePagefromCh";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            // console.log(res.data);
            setselectedVideoLists({
               selectedVideoLists:res.data.videolists,
               finished:true,
            });
         })
         .catch( (error) => {
            console.log(error);
            setselectedVideoLists({
               selectedVideoLists:[],
               finished:true,
            });
      });
   }

   const getChannelKanrenWordsFromCh = (param) => {
      setkanrenWords({
         kanrenWords:[],
         finished:false
      });
      var type="getChannelKanrenWordsFromCh";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            // console.log(res.data);
            var pairs = Object.entries(res.data.wordresult);
            pairs.sort(function(p1, p2){
               var p1Val = p1[1], p2Val = p2[1];
               return p2Val - p1Val;
            })
            setkanrenWords({
               kanrenWords:pairs,
               finished:true
            });
         })
         .catch( (error) => {
            console.log(error);
            setkanrenWords({
               kanrenWords:[],
               finished:true
            });
      });
   }

   const getChannelKanrenPostsFromCh = (param) => {
      setkanrenPosts({
         kanrenPosts:[],
         finished:false
      });
      var type="getChannelKanrenPostsFromCh";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            console.log(res.data.postresult);
            setkanrenPosts({
               kanrenPosts:res.data.postresult,
               finished:true
            });
         })
         .catch( (error) => {
            console.log(error);
            setkanrenPosts({
               kanrenPosts:[],
               finished:true
            });
      });
   }

   const reflash = (rettemp) => {
      // console.log(rettemp);
      setParams(rettemp);
      getVideoListsOnePagefromCh(rettemp);
      getChannelKanrenPostsFromCh(rettemp)
   }

   useEffect(() =>{
      let temp = getWindowParam(params);
      setParams(temp);
      getVideoCountfromCh(temp);
      getChannelDetails(temp);
      getChannelKanrenWordsFromCh(temp);
      getChannelKanrenPostsFromCh(temp);
      reflash(temp);

   },[]);
   
   return (
      <Layout 
         title={"チャンネル詳細"}
         description={"YouTubeチャンネルの詳しい数値を掲載しています"}
         // image={}
         uri={pageprops.uri}
      >
         
         { channeldetail.finished ?
            
            channeldetail.channeldetail.length > 0 ?
               channeldetail.channeldetail[0].channelTitle === "" ?
               <Midasi4 mb="0.5rem" ch={params.ch}>データ準備中...</Midasi4>
               :
               <>
               <Midasi4 mb="0.5rem" ch={params.ch}>{channeldetail.channeldetail[0].channelTitle}</Midasi4>
               <Center>
                  <Image m="1rem" boxSize="30%" src={channeldetail.thumbnailUrl} />
               </Center>
               
               <Midasi5>概要</Midasi5>
               <Box bg="#fff" mx="0.75rem" p="1rem">
                  
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem" borderBottom="1px #d9d9d9b8 solid">
                     <WrapItem>チャンネル登録者数 : </WrapItem>
                     <Spacer />
                     <WrapItem>{num2ja(channeldetail.channeldetail[0].registCount)}人</WrapItem>
                  </Wrap>
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem" borderBottom="1px #d9d9d9b8 solid">
                     <WrapItem>再生回数 : </WrapItem>
                     <Spacer />
                     <WrapItem>{num2ja(channeldetail.channeldetail[0].viewsCount)}回</WrapItem>
                  </Wrap>
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem" borderBottom="1px #d9d9d9b8 solid">
                     <WrapItem>動画数 : </WrapItem>
                     <Spacer />
                     <WrapItem>
                        {/* {channeldetail.channeldetail[0].videosCount + "本 (取得済み ： "} */}
                        {videosCnt.finished?
                           videosCnt.videosCnt
                        :
                           <Spinner as={Icon} size="xs" />
                        }
                     </WrapItem>
                  </Wrap>
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem" borderBottom="1px #d9d9d9b8 solid">
                     <WrapItem>YouTube登録日 : </WrapItem>
                     <Spacer />
                     <WrapItem>{channeldetail.channeldetail[0].publishedAt}</WrapItem>
                  </Wrap>
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem" borderBottom="1px #d9d9d9b8 solid">
                     <WrapItem>人柄・属性 : </WrapItem>
                     <Spacer />
                     <WrapItem>{channeldetail.channeldetail[0].myStatue}</WrapItem>
                  </Wrap>
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem" borderBottom="1px #d9d9d9b8 solid">
                     <WrapItem>内容 : </WrapItem>
                     <Spacer />
                     <WrapItem>{channeldetail.channeldetail[0].myCategory}</WrapItem>
                     
                  </Wrap>
                  <Box spacing="0" fontSize="1rem" mt="0.5rem" borderBottom="1px #d9d9d9b8 solid">
                     <Box>推移 : </Box>
                     {/* 
                     <Chart1 data={channeldetail.channeldetail[0]}/>
                     */}
                  </Box>
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem">
                     <WrapItem>タグ・キーワード : </WrapItem>
                     <Spacer />
                     <WrapItem>
                        <Wrap>
                        {kanrenWords.finished ? 
                           kanrenWords.kanrenWords.length > 0 ?
                           React.Children.toArray(kanrenWords.kanrenWords.map(val => {
                              let key = val[0];
                              return (
                                 <>
                                 <WrapItem>
                                    <Tooltip hasArrow label={val[1]}>
                                    <Button 
                                       colorScheme="facebook"
                                       borderRadius="0.5rem"
                                       fontSize="0.7rem"
                                       ml="0.3rem"
                                       mt="0.3rem"
                                       px="0.5rem"
                                       height="1.5rem"
                                       // as={GatsbyLink} 
                                       as={Link} 
                                       href={"/videolist"
                                          + "?keyword=" + encodeURIComponent(key)}
                                    >
                                       {key}
                                    </Button>
                                    </Tooltip>
                                 </WrapItem>
                                 </>
                              )
                           }))
                           :
                           ""
                        :
                        ""
                        }
                        </Wrap>
                     </WrapItem>
                  </Wrap>
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem">
                     <WrapItem>説明 : </WrapItem>
                     <WrapItem>
                        <Wrap>
                           <WrapItem p="1rem" fontSize="0.8rem" wordBreak="break-all">
                              <AutoBrLinkMoreParser
                                 str={channeldetail.channeldetail[0].description}
                                 show={show}
                                 setShow={setShow}
                              />
                           </WrapItem>
                        </Wrap>
                     </WrapItem>
                  </Wrap>
                  {/* </Box> */}
               </Box>

               
               <Midasi5>ビデオ</Midasi5>
               <Box mx="0.75rem" mt="1rem">
                  {/*
                  <Select 
                     my="0.5rem" 
                     icon={<BiSort />} 
                     bg="#fff" 
                     fontSize="0.9rem" 
                     fontWeight="bold"
                     onChange={(e) => {
                        reflash({
                           ch:params.ch,
                           duration:params.duration,
                           dateOrViewOrRegist:e.target.value,
                           page:params.page,
                        });
                        if(e.target.value === "日付" || e.target.value === "日付s"){
                           setisDisabled(true);
                        }else{
                           setisDisabled(false);
                        }
                     }}
                     // onChange={(e) => {selectViewOrRegistChange(e.target.value)}}
                  >
                     <option value="日付">日付順</option>
                     <option value="日付s">日付順（昇順）</option>
                     <option value="再生回数">再生回数順</option>
                     <option value="再生回数s">再生回数順（昇順）</option>
                     <option value="ライク数">ライク順</option>
                     <option value="ライク数s">ライク順（昇順）</option>
                     <option value="コメント数">コメント順</option>
                     <option value="コメント数s">コメント順（昇順）</option>
                  </Select>
                  */}
                  {/*
                  <RadioButtonsC5v1 
                     name="selectDuration"
                     defaultValue={"全期間"}
                     handleclick={(e) => {
                        reflash({
                           ch:params.ch,
                           duration:e,
                           dateOrViewOrRegist:params.dateOrViewOrRegist,
                           page:params.page,
                        });
                     }}
                     // handleclick={selectDurationChange}
                     items={["全期間","日間","週間","月間"]}
                     isDisabled={isDisabled}
                  />
                  */}
               </Box>
               <Box bg="#fff" mx="0.75rem" >
                  {selectedVideoLists.finished ?
                     selectedVideoLists.selectedVideoLists.length > 0 ? 
                        <>
                        <Box pt="0.4rem">
                           {/* {console.log(selectedVideoLists)} */}
                           {React.Children.toArray(selectedVideoLists.selectedVideoLists.map((val,index) => {
                              return (
                                 <>
                                 <Box borderBottom="1px solid #e0e0e0" px="1rem" pb="0.5rem">

                                    <Box mt="1rem">
                                       <Link
                                          ml="1rem"
                                          // as={GatsbyLink} 
                                          href={pageprops.location.origin + "/videodetail"
                                             + "?vi=" + val.videoId}
                                          fontWeight={"bold"}
                                          fontSize="1.1rem"
                                          lineHeight="1.4rem"
                                          isExternal
                                       >
                                          {val.videoTitle}
                                       </Link>
                                    </Box>

                                    <Box p="0.5rem" display={["block","flex"]}>
                                       <Center px="1.5rem">
                                          <Image
                                             src={val.trimedvi}
                                             alt={val.videoTitle}
                                             w="100%"
                                          />
                                       </Center>
                                       
                                       <Box fontSize="0.8rem" mt="0.5rem" w="100%">
                                       <Wrap spacing="0" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>投稿日 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{val.publishedAt}</WrapItem>
                                 </Wrap>
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>再生回数 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{num2ja(val.viewsCount)}回</WrapItem>
                                 </Wrap>
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>ライク数 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{num2ja(val.likeCount)}回</WrapItem>
                                 </Wrap>
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>コメント数 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>{num2ja(val.commentCount)}回</WrapItem>
                                 </Wrap>
                                 {
                                    params.category != "急上昇" &&
                                    {
                                       "全期間":
                                          <>
                                          </>
                                          ,
                                       "日間":
                                          <>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>日間再生回数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.viewsCountDayDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>日間ライク数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.likeCountDayDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>日間コメント数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.commentCountDayDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>日間比較日付 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{val.dayDiffTarget}</WrapItem>
                                          </Wrap>
                                          </>
                                          ,
                                       "週間":
                                          <>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>週間再生回数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.viewsCountWeekDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>週間ライク数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.likeCountWeekDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>週間コメント数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.commentCountWeekDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>週間比較日付 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{val.weekDiffTarget}</WrapItem>
                                          </Wrap>
                                          </>
                                          ,
                                       "月間":
                                          <>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>月間再生回数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.viewsCountMonthDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>月間ライク数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.likeCountMonthDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>月間コメント数 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{num2ja(val.commentCountMonthDiff)}回</WrapItem>
                                          </Wrap>
                                          <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                             <WrapItem>月間比較日付 : </WrapItem>
                                             <Spacer />
                                             <WrapItem>{val.monthDiffTarget}</WrapItem>
                                          </Wrap>
                                          </>
                                          ,
                                    }[params.duration]
                                 }
                                 <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                    <WrapItem>チャンネル名 : </WrapItem>
                                    <Spacer />
                                    <WrapItem>
                                       {val.channelTitle}
                                    </WrapItem>
                                 </Wrap>

                                       </Box>
                                    </Box>
                                 </Box>
                                 </>
                              )
                           }))}
                        </Box>
                        <Pagenavi1 
                           params={params} 
                           pagecounts={videosCnt.pagecounts} 
                           pager={pager}
                           setPager={setPager}
                           handleclick={reflash} 
                        />
                        </>
                     :
                        <Box>
                           <Text>取得済みの動画データがありません</Text>
                        </Box>
                  :
                     <>
                     <Box pt="0.4rem">
                        {
                        React.Children.toArray([...Array(10)].map(() => {
                           return (
                              <Box borderBottom="1px solid #e0e0e0" px="1rem" pb="0.5rem">
                                 <Box mt="1rem">
                                    <Skeleton ml="1rem" height="1.4rem">SkeletonText</Skeleton>
                                 </Box>

                                 <Box p="0.5rem" display={["block","flex"]}>
                                    <Center px="1.5rem">
                                       <Skeleton
                                          w="100%"
                                       >
                                          <Image src={"https://via.placeholder.com/320x180"}>

                                          </Image>
                                          </Skeleton>
                                    </Center>
                                    
                                    <Box fontSize="0.8rem" mt="0.5rem" w="100%">
                                       <Wrap spacing="0" borderBottom="1px #d9d9d9b8 solid">
                                          <Skeleton h="0.8rem" w="100%">SkeletonText</Skeleton>
                                       </Wrap>
                                       <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                          <Skeleton h="0.8rem" w="100%">SkeletonText</Skeleton>
                                       </Wrap>
                                       <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                          <Skeleton h="0.8rem" w="100%">SkeletonText</Skeleton>
                                       </Wrap>
                                       <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                          <Skeleton h="0.8rem" w="100%">SkeletonText</Skeleton>
                                       </Wrap>

                                    </Box>
                                 </Box>
                              </Box>
                           )
                        }))
                        }
                     </Box>
                     <Center p="4rem">
                        {/* ページナビ部分 */}
                     </Center>
                     </>
               }
                  
               </Box>
               
               <Midasi5>関連ニュース</Midasi5>
               <Box px="0.75rem" display="flex" flexWrap="wrap">
                  {kanrenPosts.finished ? 
                     kanrenPosts.kanrenPosts.data ?
                        React.Children.toArray(kanrenPosts.kanrenPosts.data.posts.nodes.map((node,index) => {
                        
                           return (
                              <Box bg="#fff" mb="1rem" w="50%" verticalAlign="top" boxSizing="border-box" 
                                 borderRight={(index+1)%2 == 0 ?"none":"0.375rem solid #eee"}
                                 borderLeft={(index+1)%2 == 0 ?"0.375rem solid #eee":"none"}
                              >
                                 <Link as={GatsbyLink} to={node.uri}>
                                    <Image 
                                       w="100%" 
                                       verticalAlign="bottom" 
                                       src={node.featuredImage ? node.featuredImage.node.mediaItemUrl : ""}
                                       fallbackSrc="https://via.placeholder.com/337x190" 
                                    />
                                    <Text
                                       py="1.25rem"
                                       px="1rem"
                                       bg="#fff"
                                       fontSize="1.0rem"
                                       fontWeight="bold"
                                       lineHeight="1.5rem"
                                       boxSizing="border-box"
                                       wordBreak="break-all"
                                    >
                                       {node.title}
                                    </Text>
                                 </Link>
                              </Box>
                           )
                        }))
                     :
                        <Box>
                           <Text>該当する関連ニュースはありません</Text>
                        </Box>
                  :
                     ""
                  }

                  <Text w="100%"
                     rounded="sm"
                     fontSize="1.3rem"
                     fontWeight="bold"
                     lineHeight="4rem"
                     textAlign="center"
                     color="#fff"
                     bg="linkedin.800"
                     position="relative"
                     _before={{
                        content:'""',
                        display:"block",
                        position:"absolute",
                        top:"1.6rem",
                        right:"2rem",
                        w:"1rem",
                        h:"1rem",
                        borderTop:"0.2rem solid #fff",
                        borderRight:"0.2rem solid #fff",
                        transform:"rotate(45deg)"
                     }}
                     as={GatsbyLink}
                     to="/category/news/1"
                  >もっと見る</Text>
               </Box> 


               </>
            
            :
               <Text>DBに登録されていません</Text>
         :
            <Spinner m="5" />
         }
      </Layout>
   )
}
export default ChannelDetail;